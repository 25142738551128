.valueBox {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  border: 1px solid #ccc;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transition: all 0.2s ease;
  font-size: 14px;
  color: #555;
}

.valueBox:hover {
  background-color: #f5f5f5;
}

.valueBox.selected {
  background-color: var(--thm-primary);
  color: white;
  border-color: var(--thm-primary);
}
