.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: white;
  border-radius: 8px;
  position: relative;
  max-width: 1100px;
  max-height: 95vh;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.popupClose {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}

.popupClose:hover {
  color: #666;
}

@media (max-width: 440px) {
  .popupContent {
    width: 95%;
  }

  .popupClose {
    top: 14px;
    right: 14px;
  }
}
