label,
input {
  padding: 0;
  margin: 0;
}

.quoteRequestPopup {
  font-size: 14px;
  letter-spacing: -0.03em;
  text-align: left;
  padding: 60px 70px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 623px) {
  .quoteRequestPopup {
    padding: 40px 50px;
  }
}

@media (max-width: 440px) {
  .quoteRequestPopup {
    padding: 40px 30px;
  }
}

/* Form */

.form {
  display: flex;
  width: 100%;
  display: flex;
  gap: 80px;
}

.left,
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.divider {
  width: 0.5px;
  background-color: #ddd;
}

@media (max-width: 960px) {
  .form {
    flex-direction: column;
    gap: 40px;
  }

  .divider {
    display: none;
  }
}

.subtitle {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #264efa;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.label {
  font-weight: 600;
}

.required {
  color: red;
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.title,
.label {
  color: #3a3a3b;
}

.input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Range */

.rangeInput {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  height: 9px;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  width: 100%;
  margin: 8px 0;
}

.rangeValue {
  text-align: right;
  font-size: 14px;
  color: #555;
}

/* Scenarios */

.scenariosList {
  display: flex;
  flex-direction: column;
}

.scenarioLabel {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.scenarioCheckbox {
  transform: translateY(0.5px);
  height: 15px;
  width: 15px;
  accent-color: #2c6efe;
  cursor: pointer;
  border-radius: 4px;
}

/* Maximum Users */

.numberInput {
  width: 80px;
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.numberInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Buttons */

.formButtons {
  margin-top: auto;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

@media (max-width: 440px) {
  .formButtons {
    margin-top: 20px;
  }
}

.submitBtn,
.cancelBtn {
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  padding: 5.5px 28.5px;
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.submitBtn {
  background-color: var(--thm-primary);
}

.submitBtn:hover {
  background-color: var(--thm-black);
  color: #ffffff;
}

.cancelBtn {
  background-color: #6c757d;
}

.cancelBtn:hover {
  background-color: var(--thm-black);
  color: #ffffff;
}

/* End Messages */

.endMessageContainer {
  min-height: 250px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.successMessage,
.errorMessage {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.successMessage {
  color: #2e7d32;
}

.errorMessage {
  color: #d32f2f;
}

.retryBtn,
.closeBtn {
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.closeBtn {
  background-color: #2e7d32;
}

.retryBtn {
  background-color: #d32f2f;
}
