.timeInputGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 6px 0;

}

.valueBoxesContainer {
  height: min-content;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.unitSelect {
  margin-left: 10px;
  width: 115px;
  height: 32px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

.unitSelect:hover {
  background-color: #f5f5f5;
}

.unitSelect:focus {
  outline: none;
  border-color: #007bff;
}
